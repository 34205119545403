.badge {
	vertical-align: 4px;
	border-radius: 10px;
	padding: 0 8px;
	border: 1px solid #90a4ae;
	background-color: white;
	color: #546e7a;
	font-size: 0.8rem;
	font-weight: 500;
}

$chipSide: 30px;
.chips-mini {
	display: inline-block;
	font-size: 0.8rem;
	font-weight: bold;
	span {
		display: inline-block;
		vertical-align: middle;
		width: $chipSide;
		height: $chipSide;
		line-height: $chipSide;
		margin: 2px;
		text-align: center;
		border-radius: 50%;
		text-overflow: ellipsis;
		overflow: hidden;

		+ span {
			width: auto;
			border-radius: 0;
		}
	}
}
.chips-mini-container {
	text-align: right;
}
.chips-label {
	margin-right: 4px;
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	font-size: 0.93rem;
	line-height: 0.93rem;
	font-weight: 500;
	border: none;
	padding: 4px 12px;
	border-radius: 28px;
	text-align: center;
}

.UNSENT,
.UNAPPROVED,
.DRAFT {
	color: inherit;
	background-color: map-get($mat-secondary, 100);
}
.PARTIAL,
.BOUNCED,
.PENDING {
	background-color: $color-orange;
	color: #ffffff;
	font-weight: 500;
}

.PENDING_EUROITALIA {
	background-color: $color-blue-light;
	font-weight: 500;
}

.MORE_INFO_NEEDED {
	background-color: $color-yellow;
	color: #ffffff;
	font-weight: 500;
}

.PENDING_AGENCY {
	background-color: $color-orange-dark;
	color: #ffffff;
	font-weight: 500;
}

.APPROVED,
.COMPLETED,
.SENT_TO_CLIENT {
	background-color: $color-green;
	color: #ffffff;
	font-weight: 500;
}

.COMPLETED_AGENCY {
	background-color: $color-yellow;
	color: inherit;
	font-weight: 500;
}

.closed {
	background-color: $color-green;
	color: #ffffff;
	font-weight: 500;
}

.REJECTED {
	background-color: $color-red;
	color: #ffffff;
	font-weight: 500;
}

// mat-fab-button
.mat-fab-button {
	position: fixed !important;
	bottom: 1rem;
	right: 1rem;
}

// generators
// classes
@include backgroundColorGenerator($mat-primary, primary);

// input
input:read-only:not(:disabled) {
	opacity: 0.5;
}
.opacity-50 {
	opacity: 0.5;
}
.placeholder {
	font-style: italic;
	opacity: 0.5;
}
